<!-- 路由页面 -->
<template>
  <div class="router" v-loading="true"></div>
</template>

<script>
export default {
  name: "Router"
}
</script>

<style lang="scss" scoped>
.router{
  width: 100%;
  height: 100vh;
}
</style>